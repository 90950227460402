@tailwind base;
@tailwind components;
@tailwind utilities;

div,
a,
button {
	-webkit-tap-highlight-color: transparent;
}

.firebase-emulator-warning {
	display: none;
}

.form-tick:checked {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
	background-size: 100% 100%;
	background-position: 50%;
	background-repeat: no-repeat;
}

.scrollbar-none::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	background: transparent; /* Chrome/Safari/Webkit */
}

.scrollbar-none {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE 10+ */
}

select:disabled {
	opacity: 1 !important;
}

.link {
	@apply text-main;
}
.link:hover {
	@apply underline;
}

.login-logo {
	height: 200px;
}
